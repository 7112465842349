import React from "react";
import { Button, Form, Input } from "antd";
import { isMobile } from "react-device-detect";
import { md5 } from "js-md5";
import { toast } from "react-toastify";

const Login = ({ setUserExist }) => {
  const onFinish = (values) => {
    if (
      values.username === "framnz85" &&
      md5(values.password) === "8d17df97833dddfdd1c58b1b5faf763d"
    ) {
      localStorage.setItem("user", "1");
      setUserExist(true);
      toast.success("Successfully logged in!");
    } else {
      toast.error("Incorrect username or password!");
    }
  };

  return (
    <div style={{ paddingTop: 70 }}>
      <h1>Clavstore Hoster</h1>
      <Form
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: isMobile ? 350 : 600,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input style={{ fontSize: 32 }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password style={{ fontSize: 32 }} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: isMobile ? 0 : 6,
            span: 16,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ fontSize: 28, height: 75, width: 250 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
