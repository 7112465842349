import React, { useState } from "react";
import { Button, Form, Input, Modal, Checkbox } from "antd";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { toast } from "react-toastify";

const upgradeType = [
  {
    type: "1",
    name: "Cosmic Clavstore",
  },
  {
    type: "2",
    name: "Dedicated Clavstore",
  },
];

const Cosmic = ({ setUserExist }) => {
  const [estore, setEstore] = useState({});
  const [billingHistory, setBillingHistory] = useState([]);
  const [owner, setOwner] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    const checkCosmic = await axios.post(
      process.env.REACT_APP_API + "/gratis/check-cosmic/",
      values
    );
    if (checkCosmic.data.err) {
      toast.error(checkCosmic.data.err);
    } else {
      setEstore(checkCosmic.data.estore);
      setBillingHistory(checkCosmic.data.estore.billingHistory);
      setOwner(checkCosmic.data.owner);
      setIsModalOpen(true);
    }
  };

  const onChange = (e, billid, type) => {
    if (e.target.checked) {
      setEstore({
        ...estore,
        billingHistory: estore.billingHistory.map((bill) =>
          bill._id === billid ? { ...bill, payStatus: "Paid" } : bill
        ),
        upStatus: "Active",
        upgradeType: type,
      });
    } else {
      setEstore({
        ...estore,
        billingHistory: estore.billingHistory.map((bill) =>
          bill._id === billid ? { ...bill, payStatus: "Pending" } : bill
        ),
        upStatus: "Pending",
        upgradeType: type,
      });
    }
  };

  const handleOk = async () => {
    if (estore && estore.upStatus) {
      const echange = estore.estoreChange > 0 ? estore.estoreChange + 1 : 1;
      const checkCosmic = await axios.put(
        process.env.REACT_APP_API + "/gratis/approve-cosmic/",
        { ...estore, estoreChange: echange }
      );
      if (checkCosmic.data.err) {
        toast.error(checkCosmic.data.err);
      } else {
        setIsModalOpen(false);
        toast.success("Store was successfully approved!");
      }
    } else {
      setIsModalOpen(false);
      toast.error("Sorry, no billing is set for this account!");
    }
  };

  return (
    <div style={{ paddingTop: 70 }}>
      <h1>Approving Cosmic</h1>
      <Form
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: isMobile ? 350 : 450,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>

        <Form.Item label="Slug" name="slug">
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: isMobile ? 0 : 5,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Check Info
          </Button>
          <br />
          <br />
          <br />
          <Button
            type="default"
            onClick={() => {
              localStorage.removeItem("user");
              setUserExist(false);
            }}
          >
            Logout
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Store Found"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => setIsModalOpen(false)}
        okText="Submit"
      >
        {estore && estore.upStatus ? (
          <div style={{ padding: "20px 0" }}>
            <strong>Store Name:</strong> {estore.name}
            <br />
            <strong>Owner:</strong> {owner.name}
            <br />
            <strong>Email:</strong> {estore.email}
            <br />
            <strong>Slug:</strong> {estore.slug}
            <br />
            <br />
            <strong>Billing:</strong>
            {billingHistory
              .filter((bill) => bill.payStatus === "Pending")
              .map((bill) => {
                const upgradeName = upgradeType.find(
                  (res) => res.type === bill.upgradeType
                );
                return (
                  <div key={bill._id}>
                    <Checkbox
                      onChange={(e) => onChange(e, bill._id, bill.upgradeType)}
                    >
                      {upgradeName.name}
                    </Checkbox>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>
            <div style={{ color: "red" }}>
              Store has no billing yet. Create first by using the details below
            </div>
            <br />
            <strong>Link:</strong>{" "}
            <div
              onClick={() => window.open("https://cosmic.clavstore.com/")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              https://cosmic.clavstore.com/
            </div>
            <br />
            <strong>Email:</strong> {owner.email}
            <br />
            <strong>Password:</strong> {owner.showPass}
            <br />
            <br />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Cosmic;
