import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

import Login from "./pages/Login";
import Header from "./pages/Header";
import Cosmic from "./pages/Cosmic";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const user = localStorage.getItem("user");

  const [userExist, setUserExist] = useState(user ? true : false);

  return (
    <div align="center" className="App">
      <Header />
      {userExist ? (
        <Cosmic setUserExist={setUserExist} />
      ) : (
        <Login setUserExist={setUserExist} />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
