import React from "react";

const Header = () => {
  const menuStyle = {
    mainContainer: {
      backgroundColor: "#009A57",
      height: "75px",
      width: "100%",
    },
  };

  return <div style={menuStyle.mainContainer}></div>;
};

export default Header;
